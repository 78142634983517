import React from "react"
import { useForm, ValidationError } from "@formspree/react"
import { Col, Container, Row } from "react-awesome-styled-grid"
import { Heading } from "../components/Heading"
import DivHelper from "../components/DivHelper"
import Layout from "../components/Layout"
import Block from "../components/Block"
import styled from "styled-components"

const InputWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
`

const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  border-radius: 20px;
  outline: none;
  border: 1px solid #aaa;
  background-color: #f9f9f9;
  padding: 10px 15px;
  ${props => props.textarea && "min-height: 120px;"}
  transition: border-color .2s, background-color .2s;

  &:hover,
  &:focus {
    border-color: #3795ed;
  }

  &:focus {
    background-color: #dbeeff;
  }
`

const Submit = styled.button`
  font-weight: 600;
  border-radius: 40px;
  outline: none;
  border: none;
  background-color: #3795ed;
  color: #fff;
  padding: 15px 20px;
  transition: filter 0.3s;
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }
`

const Kontakt = () => {
  const [state, handleSubmit] = useForm("xqknejvr")

  return (
    <Layout title="Kontakt">
      <Container>
        <Row>
          <Col>
            <DivHelper center>
              <Heading>Kontakt</Heading>
            </DivHelper>
          </Col>
        </Row>
        <Block>
          <Row>
            <Col>
              <p>
                Sie bieten auch CBD Produkte an und sind davon überzeugt?
                Schreiben Sie uns!
              </p>
            </Col>
          </Row>
          <Row>
            {state.succeeded ? (
              <Col>
                <InputWrapper>
                  <p>
                    Vielen Dank für Ihre Nachricht! Wir werden uns so schnell
                    wie möglich bei Ihnen melden.
                  </p>
                </InputWrapper>
              </Col>
            ) : (
              <Col xs={12} sm={10} md={8} lg={6} xl={4}>
                <form onSubmit={handleSubmit}>
                  <input type="text" name="_gotcha" style="display:none" />
                  <input type="hidden" name="_language" value="de" />
                  <InputWrapper>
                    <Input type="name" name="name" placeholder="Name" />
                  </InputWrapper>
                  <InputWrapper>
                    <Input
                      type="email"
                      name="email"
                      placeholder="E-Mail Adresse"
                      required
                    />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Input
                      type="tel"
                      name="tel"
                      placeholder="Telefon (optional)"
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Input
                      as="textarea"
                      id="message"
                      name="message"
                      placeholder="Ihre Nachricht an uns"
                      required
                      textarea
                    />
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <Submit type="submit">Senden</Submit>
                  </InputWrapper>
                </form>
              </Col>
            )}
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export default Kontakt
